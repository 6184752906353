import _ from 'lodash'
import { Link } from 'gatsby'
import styled from 'styled-components'
import React, { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { device } from '../../theme/device'
import { makeRequest, useWindowDimensions, changelanguages } from '../../helpers'
import iconCruiser from '../../assets/images/TAM_cruise_icon.png'
import iconAirplane from '../../assets/images/TAM_airline_icon.png'
import {
  Wrapper, Spinner, ButtonMore, H1, P, Seo,
} from '../../components'

import './what-we-offer.scss'

import BoxInfo from './components/box-info'

const Text = styled.p`
text-align: center;
margin-top: 64px;
margin-bottom: 120px;
color: #7B7B7B;
font-family: Manrope;
font-size: 28px;
font-weight: 300;
letter-spacing: 0;
text-decoration: underline;
line-height: 48px;
  &:hover {
    color: #E20613;
  }
  @media ${device.desktop} {
    margin-top: 120px;
    margin-bottom: 0px;
    text-align: left;
    font-size: 34px;
  }
`

const WrapperContainerImage = styled((props) => <Wrapper {...props} />)`

  @media ${device.tablet} {
    flex-direction: row;
    margin-bottom: 0;
  }
  
`

const WhatWeOfferDeatil = (props) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const { t, i18n } = useTranslation()
  const { params } = props

  const gotoSlug = params.slug === 'cargo' ? 'passenger' : 'cargo'
  const size = useWindowDimensions()
  const width = _.get(size, 'width', 0)
  const getOfferDetails = useCallback(async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'pages',
      params: {
        lang: changelanguages(i18n.language),
        ...params,
      },
    }).then((resp) => {
      setData(resp.data[0])
      setLoading(false)
    })
  }, [params, i18n.language])

  useEffect(() => {
    getOfferDetails()
  }, [i18n.language, params])

  const buttontext = params.slug === 'cargo' ? t('offer.buttons.passengerView') : t('offer.buttons.cargoView')
  if (loading) {
    return <Spinner loading={loading} />
  }
  return (
    <Wrapper
      justifyContentD="flex-start"
      style={{
        // minHeight: 'calc(200vh - 100px)',
      }}
      paddingD="90px 0px"
      paddingM="24px 25px"
      flexDirectionD="column"
    >
      <Seo
        title={_.get(data, 'yoast_head_json.title')}
        description={_.get(data, 'yoast_head_json.description')}
        schema={_.get(data, 'yoast_head_json.schema')}
        type={_.get(data, 'yoast_head_json.og_type', {})}
      />
      {width < 420 && (
        <Wrapper

          heightM="59px"
          marginM="0 0 32px 0"
          paddingM="0 28px 0 0"
          paddingD="0 0 0 70px"
          justifyContentD="flex-start">
          <ButtonMore link={'/what-we-offer/'} >{t('offer.buttons.back')}</ButtonMore>
        </Wrapper>

      )}
      <WrapperContainerImage
        paddingD="0 15px"
        alignItemsD="flex-start"
        widthD="1240px">
        <Wrapper
          flexDirectionD="column"
          alignItemsD="flex-start">
          {
            width >= 420 && (
              <Wrapper
                heightM="120px"
                alignItemsM="flex-start"
                paddingM="0 0 0 50px"
                paddingD="0 0 0 60px"
                justifyContentD="flex-start">
                <ButtonMore link={'/what-we-offer/'} >{t('offer.buttons.back')}</ButtonMore>
              </Wrapper>
            )
          }
          {
            width < 420 && (
              <Wrapper justifyContentD="flex-end" marginM="0 0 35px">
                <Wrapper
                  marginD="0"
                  widthD="520px"
                  // heightD="530px"
                  style={{
                    borderRadius: '50%',
                    overflow: 'hidden',
                  }}
                >
                  <img style={{ width: '100%' }} src={_.get(data, 'acf.image.url')} alt={_.get(data, 'acf.image.alt')} />
                </Wrapper>
              </Wrapper>
            )
          }
          <Wrapper
            marginD="90px 0 32px"
            justifyContentD="flex-start"
            justifyContentM="flex-start"
            alignItemsM="flex-start"
          >
            <H1>{_.get(data, 'title.rendered')}</H1>
          </Wrapper>
          {_.get(data, 'slug') === 'passenger' && (
            <Wrapper
              justifyContentM="flex-start"
              alignItemsM="flex-start"
              marginM="5px 0 30px"
              flexDirectionM="row"
            >
              <div className="image-icons">
                <img src={iconAirplane} alt="airnplane" />
                <p>Airlines</p>
              </div>
              <div className="image-icons">
                <img src={iconCruiser} alt="cruiser" />
                <p>Cruises</p>
              </div>

            </Wrapper>

          )}
          <Wrapper justifyContentD="flex-start">
            <P
              style={{
                fontSize: (width >= 768) ? '24px' : '18px',
                lineHeight: (width >= 768) ? '42px' : '32px',
              }}
              font="manrope"
              fontWeightM="light"
            >{_.get(data, 'acf.description')}</P>
          </Wrapper>
        </Wrapper>
        {
          width >= 420 && (
            <Wrapper justifyContentD="flex-end">
              <Wrapper
                marginD="0"
                widthD="530px"
                // heightD="530px"
                style={{
                  borderRadius: '50%',
                  overflow: 'hidden',
                }}
              >
                <img style={{ width: '100%' }} src={_.get(data, 'acf.image.url')} alt={_.get(data, 'acf.image.alt')} />
              </Wrapper>
            </Wrapper>
          )
        }
      </WrapperContainerImage>
      <Wrapper
        widthD="1240px"
        paddingD="0 15px"
        justifyContentD="flex-start">
        <div className="box-info-container">
          {
            _.map(_.get(data, 'acf.service_detail'), (item, idx) => (
              <BoxInfo
                key={item.name + idx}
                items={_.get(item, 'task')}
                title={_.get(item, 'name')}
                id={idx + 1}

              />
            ))
          }

        </div>
      </Wrapper>
      <Wrapper
        justifyContentM="center"
        alignItemsM="center"
      >
        <Link to={`/what-we-offer/${gotoSlug}/`}>
          <Text>
            {buttontext}
          </Text>
        </Link>
      </Wrapper>
    </Wrapper>
  )
}

export default WhatWeOfferDeatil
